export enum Country {
  AF = 'Afghanistan',
  AX = 'AlandIslands',
  AL = 'Albania',
  DZ = 'Algeria',
  AS = 'AmericanSamoa',
  AD = 'Andorra',
  AO = 'Angola',
  AI = 'Anguilla',
  AQ = 'Antarctica',
  AG = 'AntiguaAndBarbuda',
  AR = 'Argentina',
  AM = 'Armenia',
  AW = 'Aruba',
  AU = 'Australia',
  AT = 'Austria',
  AZ = 'Azerbaijan',
  BS = 'Bahamas',
  BH = 'Bahrain',
  BD = 'Bangladesh',
  BB = 'Barbados',
  BY = 'Belarus',
  BE = 'Belgium',
  BZ = 'Belize',
  BJ = 'Benin',
  BM = 'Bermuda',
  BT = 'Bhutan',
  BO = 'Bolivia',
  BQ = 'BonaireSintEustatiusSaba',
  BA = 'BosniaAndHerzegovina',
  BW = 'Botswana',
  BV = 'BouvetIsland',
  BR = 'Brazil',
  IO = 'BritishIndianOceanTerritory',
  BN = 'BruneiDarussalam',
  BG = 'Bulgaria',
  BF = 'BurkinaFaso',
  BI = 'Burundi',
  KH = 'Cambodia',
  CM = 'Cameroon',
  CA = 'Canada',
  CV = 'CapeVerde',
  KY = 'CaymanIslands',
  CF = 'CentralAfricanRepublic',
  TD = 'Chad',
  CL = 'Chile',
  CN = 'China',
  CX = 'ChristmasIsland',
  CC = 'CocosKeelingIslands',
  CO = 'Colombia',
  KM = 'Comoros',
  CG = 'Congo',
  CD = 'CongoDemocraticRepublic',
  CK = 'CookIslands',
  CR = 'CostaRica',
  CI = 'CoteDIvoire',
  HR = 'Croatia',
  CU = 'Cuba',
  CW = 'Curacao',
  CY = 'Cyprus',
  CZ = 'CzechRepublic',
  DK = 'Denmark',
  DJ = 'Djibouti',
  DM = 'Dominica',
  DO = 'DominicanRepublic',
  EC = 'Ecuador',
  EG = 'Egypt',
  SV = 'ElSalvador',
  GQ = 'EquatorialGuinea',
  ER = 'Eritrea',
  EE = 'Estonia',
  ET = 'Ethiopia',
  FK = 'FalklandIslands',
  FO = 'FaroeIslands',
  FJ = 'Fiji',
  FI = 'Finland',
  FR = 'France',
  GF = 'FrenchGuiana',
  PF = 'FrenchPolynesia',
  TF = 'FrenchSouthernTerritories',
  GA = 'Gabon',
  GM = 'Gambia',
  GE = 'Georgia',
  DE = 'Germany',
  GH = 'Ghana',
  GI = 'Gibraltar',
  GR = 'Greece',
  GL = 'Greenland',
  GD = 'Grenada',
  GP = 'Guadeloupe',
  GU = 'Guam',
  GT = 'Guatemala',
  GG = 'Guernsey',
  GN = 'Guinea',
  GW = 'GuineaBissau',
  GY = 'Guyana',
  HT = 'Haiti',
  HM = 'HeardIslandMcdonaldIslands',
  VA = 'HolySeeVaticanCityState',
  HN = 'Honduras',
  HK = 'HongKong',
  HU = 'Hungary',
  IS = 'Iceland',
  IN = 'India',
  ID = 'Indonesia',
  IR = 'Iran',
  IQ = 'Iraq',
  IE = 'Ireland',
  IM = 'IsleOfMan',
  IL = 'Israel',
  IT = 'Italy',
  JM = 'Jamaica',
  JP = 'Japan',
  JE = 'Jersey',
  JO = 'Jordan',
  KZ = 'Kazakhstan',
  KE = 'Kenya',
  KI = 'Kiribati',
  KR = 'Korea',
  KP = 'KoreaDemocraticPeoplesRepublic',
  KW = 'Kuwait',
  KG = 'Kyrgyzstan',
  LA = 'LaoPeoplesDemocraticRepublic',
  LV = 'Latvia',
  LB = 'Lebanon',
  LS = 'Lesotho',
  LR = 'Liberia',
  LY = 'LibyanArabJamahiriya',
  LI = 'Liechtenstein',
  LT = 'Lithuania',
  LU = 'Luxembourg',
  MO = 'Macao',
  MK = 'Macedonia',
  MG = 'Madagascar',
  MW = 'Malawi',
  MY = 'Malaysia',
  MV = 'Maldives',
  ML = 'Mali',
  MT = 'Malta',
  MH = 'MarshallIslands',
  MQ = 'Martinique',
  MR = 'Mauritania',
  MU = 'Mauritius',
  YT = 'Mayotte',
  MX = 'Mexico',
  FM = 'Micronesia',
  MD = 'Moldova',
  MC = 'Monaco',
  MN = 'Mongolia',
  ME = 'Montenegro',
  MS = 'Montserrat',
  MA = 'Morocco',
  MZ = 'Mozambique',
  MM = 'Myanmar',
  NA = 'Namibia',
  NR = 'Nauru',
  NP = 'Nepal',
  NL = 'Netherlands',
  NC = 'NewCaledonia',
  NZ = 'NewZealand',
  NI = 'Nicaragua',
  NE = 'Niger',
  NG = 'Nigeria',
  NU = 'Niue',
  NF = 'NorfolkIsland',
  MP = 'NorthernMarianaIslands',
  NO = 'Norway',
  OM = 'Oman',
  PK = 'Pakistan',
  PW = 'Palau',
  PS = 'PalestinianTerritory',
  PA = 'Panama',
  PG = 'PapuaNewGuinea',
  PY = 'Paraguay',
  PE = 'Peru',
  PH = 'Philippines',
  PN = 'Pitcairn',
  PL = 'Poland',
  PT = 'Portugal',
  PR = 'PuertoRico',
  QA = 'Qatar',
  RE = 'Reunion',
  RO = 'Romania',
  RU = 'RussianFederation',
  RW = 'Rwanda',
  BL = 'SaintBarthelemy',
  SH = 'SaintHelena',
  KN = 'SaintKittsAndNevis',
  LC = 'SaintLucia',
  MF = 'SaintMartin',
  PM = 'SaintPierreAndMiquelon',
  VC = 'SaintVincentAndGrenadines',
  WS = 'Samoa',
  SM = 'SanMarino',
  ST = 'SaoTomeAndPrincipe',
  SA = 'SaudiArabia',
  SN = 'Senegal',
  RS = 'Serbia',
  SC = 'Seychelles',
  SL = 'SierraLeone',
  SG = 'Singapore',
  SX = 'SintMaarten',
  SK = 'Slovakia',
  SI = 'Slovenia',
  SB = 'SolomonIslands',
  SO = 'Somalia',
  ZA = 'SouthAfrica',
  GS = 'SouthGeorgiaAndSandwichIsl',
  SS = 'SouthSudan',
  ES = 'Spain',
  LK = 'SriLanka',
  SD = 'Sudan',
  SR = 'Suriname',
  SJ = 'SvalbardAndJanMayen',
  SZ = 'Swaziland',
  SE = 'Sweden',
  CH = 'Switzerland',
  SY = 'SyrianArabRepublic',
  TW = 'Taiwan',
  TJ = 'Tajikistan',
  TZ = 'Tanzania',
  TH = 'Thailand',
  TL = 'TimorLeste',
  TG = 'Togo',
  TK = 'Tokelau',
  TO = 'Tonga',
  TT = 'TrinidadAndTobago',
  TN = 'Tunisia',
  TR = 'Turkey',
  TM = 'Turkmenistan',
  TC = 'TurksAndCaicosIslands',
  TV = 'Tuvalu',
  UG = 'Uganda',
  UA = 'Ukraine',
  AE = 'UnitedArabEmirates',
  GB = 'UnitedKingdom',
  US = 'UnitedStates',
  UM = 'UnitedStatesOutlyingIslands',
  UY = 'Uruguay',
  UZ = 'Uzbekistan',
  VU = 'Vanuatu',
  VE = 'Venezuela',
  VN = 'Vietnam',
  VG = 'VirginIslandsBritish',
  VI = 'VirginIslandsUS',
  WF = 'WallisAndFutuna',
  EH = 'WesternSahara',
  YE = 'Yemen',
  ZM = 'Zambia',
  ZW = 'Zimbabwe',
}

export const ALL_COUNTRIES = Object.values(Country)

export function isCountry(value: string): value is Country {
  return ALL_COUNTRIES.includes(value as Country)
}

export enum CountryCode {
  Afghanistan = 'AF',
  AlandIslands = 'AX',
  Albania = 'AL',
  Algeria = 'DZ',
  AmericanSamoa = 'AS',
  Andorra = 'AD',
  Angola = 'AO',
  Anguilla = 'AI',
  Antarctica = 'AQ',
  AntiguaAndBarbuda = 'AG',
  Argentina = 'AR',
  Armenia = 'AM',
  Aruba = 'AW',
  Australia = 'AU',
  Austria = 'AT',
  Azerbaijan = 'AZ',
  Bahamas = 'BS',
  Bahrain = 'BH',
  Bangladesh = 'BD',
  Barbados = 'BB',
  Belarus = 'BY',
  Belgium = 'BE',
  Belize = 'BZ',
  Benin = 'BJ',
  Bermuda = 'BM',
  Bhutan = 'BT',
  Bolivia = 'BO',
  BonaireSintEustatiusSaba = 'BQ',
  BosniaAndHerzegovina = 'BA',
  Botswana = 'BW',
  BouvetIsland = 'BV',
  Brazil = 'BR',
  BritishIndianOceanTerritory = 'IO',
  BruneiDarussalam = 'BN',
  Bulgaria = 'BG',
  BurkinaFaso = 'BF',
  Burundi = 'BI',
  Cambodia = 'KH',
  Cameroon = 'CM',
  Canada = 'CA',
  CapeVerde = 'CV',
  CaymanIslands = 'KY',
  CentralAfricanRepublic = 'CF',
  Chad = 'TD',
  Chile = 'CL',
  China = 'CN',
  ChristmasIsland = 'CX',
  CocosKeelingIslands = 'CC',
  Colombia = 'CO',
  Comoros = 'KM',
  Congo = 'CG',
  CongoDemocraticRepublic = 'CD',
  CookIslands = 'CK',
  CostaRica = 'CR',
  CoteDIvoire = 'CI',
  Croatia = 'HR',
  Cuba = 'CU',
  Curacao = 'CW',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Djibouti = 'DJ',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Egypt = 'EG',
  ElSalvador = 'SV',
  EquatorialGuinea = 'GQ',
  Eritrea = 'ER',
  Estonia = 'EE',
  Ethiopia = 'ET',
  FalklandIslands = 'FK',
  FaroeIslands = 'FO',
  Fiji = 'FJ',
  Finland = 'FI',
  France = 'FR',
  FrenchGuiana = 'GF',
  FrenchPolynesia = 'PF',
  FrenchSouthernTerritories = 'TF',
  Gabon = 'GA',
  Gambia = 'GM',
  Georgia = 'GE',
  Germany = 'DE',
  Ghana = 'GH',
  Gibraltar = 'GI',
  Greece = 'GR',
  Greenland = 'GL',
  Grenada = 'GD',
  Guadeloupe = 'GP',
  Guam = 'GU',
  Guatemala = 'GT',
  Guernsey = 'GG',
  Guinea = 'GN',
  GuineaBissau = 'GW',
  Guyana = 'GY',
  Haiti = 'HT',
  HeardIslandMcdonaldIslands = 'HM',
  HolySeeVaticanCityState = 'VA',
  Honduras = 'HN',
  HongKong = 'HK',
  Hungary = 'HU',
  Iceland = 'IS',
  India = 'IN',
  Indonesia = 'ID',
  Iran = 'IR',
  Iraq = 'IQ',
  Ireland = 'IE',
  IsleOfMan = 'IM',
  Israel = 'IL',
  Italy = 'IT',
  Jamaica = 'JM',
  Japan = 'JP',
  Jersey = 'JE',
  Jordan = 'JO',
  Kazakhstan = 'KZ',
  Kenya = 'KE',
  Kiribati = 'KI',
  Korea = 'KR',
  KoreaDemocraticPeoplesRepublic = 'KP',
  Kuwait = 'KW',
  Kyrgyzstan = 'KG',
  LaoPeoplesDemocraticRepublic = 'LA',
  Latvia = 'LV',
  Lebanon = 'LB',
  Lesotho = 'LS',
  Liberia = 'LR',
  LibyanArabJamahiriya = 'LY',
  Liechtenstein = 'LI',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Macao = 'MO',
  Macedonia = 'MK',
  Madagascar = 'MG',
  Malawi = 'MW',
  Malaysia = 'MY',
  Maldives = 'MV',
  Mali = 'ML',
  Malta = 'MT',
  MarshallIslands = 'MH',
  Martinique = 'MQ',
  Mauritania = 'MR',
  Mauritius = 'MU',
  Mayotte = 'YT',
  Mexico = 'MX',
  Micronesia = 'FM',
  Moldova = 'MD',
  Monaco = 'MC',
  Mongolia = 'MN',
  Montenegro = 'ME',
  Montserrat = 'MS',
  Morocco = 'MA',
  Mozambique = 'MZ',
  Myanmar = 'MM',
  Namibia = 'NA',
  Nauru = 'NR',
  Nepal = 'NP',
  Netherlands = 'NL',
  NewCaledonia = 'NC',
  NewZealand = 'NZ',
  Nicaragua = 'NI',
  Niger = 'NE',
  Nigeria = 'NG',
  Niue = 'NU',
  NorfolkIsland = 'NF',
  NorthernMarianaIslands = 'MP',
  Norway = 'NO',
  Oman = 'OM',
  Pakistan = 'PK',
  Palau = 'PW',
  PalestinianTerritory = 'PS',
  Panama = 'PA',
  PapuaNewGuinea = 'PG',
  Paraguay = 'PY',
  Peru = 'PE',
  Philippines = 'PH',
  Pitcairn = 'PN',
  Poland = 'PL',
  Portugal = 'PT',
  PuertoRico = 'PR',
  Qatar = 'QA',
  Reunion = 'RE',
  Romania = 'RO',
  RussianFederation = 'RU',
  Rwanda = 'RW',
  SaintBarthelemy = 'BL',
  SaintHelena = 'SH',
  SaintKittsAndNevis = 'KN',
  SaintLucia = 'LC',
  SaintMartin = 'MF',
  SaintPierreAndMiquelon = 'PM',
  SaintVincentAndGrenadines = 'VC',
  Samoa = 'WS',
  SanMarino = 'SM',
  SaoTomeAndPrincipe = 'ST',
  SaudiArabia = 'SA',
  Senegal = 'SN',
  Serbia = 'RS',
  Seychelles = 'SC',
  SierraLeone = 'SL',
  Singapore = 'SG',
  SintMaarten = 'SX',
  Slovakia = 'SK',
  Slovenia = 'SI',
  SolomonIslands = 'SB',
  Somalia = 'SO',
  SouthAfrica = 'ZA',
  SouthGeorgiaAndSandwichIsl = 'GS',
  SouthSudan = 'SS',
  Spain = 'ES',
  SriLanka = 'LK',
  Sudan = 'SD',
  Suriname = 'SR',
  SvalbardAndJanMayen = 'SJ',
  Swaziland = 'SZ',
  Sweden = 'SE',
  Switzerland = 'CH',
  SyrianArabRepublic = 'SY',
  Taiwan = 'TW',
  Tajikistan = 'TJ',
  Tanzania = 'TZ',
  Thailand = 'TH',
  TimorLeste = 'TL',
  Togo = 'TG',
  Tokelau = 'TK',
  Tonga = 'TO',
  TrinidadAndTobago = 'TT',
  Tunisia = 'TN',
  Turkey = 'TR',
  Turkmenistan = 'TM',
  TurksAndCaicosIslands = 'TC',
  Tuvalu = 'TV',
  Uganda = 'UG',
  Ukraine = 'UA',
  UnitedArabEmirates = 'AE',
  UnitedKingdom = 'GB',
  UnitedStates = 'US',
  UnitedStatesOutlyingIslands = 'UM',
  Uruguay = 'UY',
  Uzbekistan = 'UZ',
  Vanuatu = 'VU',
  Venezuela = 'VE',
  Vietnam = 'VN',
  VirginIslandsBritish = 'VG',
  VirginIslandsUS = 'VI',
  WallisAndFutuna = 'WF',
  WesternSahara = 'EH',
  Yemen = 'YE',
  Zambia = 'ZM',
  Zimbabwe = 'ZW',
}
export const ALL_COUNTRIES_CODE = Object.values(CountryCode)
export function isCountryCode(value: string): value is CountryCode {
  return ALL_COUNTRIES_CODE.includes(value as CountryCode)
}
