const Fail = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        viewBox="0 0 24 24"
      >
        <path d="m10.595,9.956l.641,3.044h-1.688l.686-3.104c.03-.149.105-.253.176-.296l.017.006c.024.016.121.118.168.349Zm13.405-1.956v8c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5v-8C0,5.243,2.243,3,5,3h14c2.757,0,5,2.243,5,5Zm-17,.777c0-.429-.348-.777-.777-.777h-1.445c-.429,0-.777.348-.777.777v6.445c0,.429.348.777.777.777h.045c.429,0,.777-.348.777-.777v-2.223h.623c.429,0,.777-.348.777-.777v-.045c0-.429-.348-.777-.777-.777h-.623v-1.801h.623c.429,0,.777-.348.777-.777v-.045Zm6.303,6.284l-1.142-5.435c-.135-.668-.523-1.213-1.035-1.459-.232-.112-.476-.168-.718-.168-.282,0-.561.075-.816.225-.471.277-.809.776-.926,1.356l-1.165,5.479c-.103.484.266.939.76.939.365,0,.68-.254.759-.61l.175-.79h2.379l.165.782c.076.36.393.617.761.617h.044c.494,0,.862-.454.761-.937Zm2.297-6.284c0-.429-.348-.777-.777-.777h-.045c-.429,0-.777.348-.777.777v6.445c0,.429.348.777.777.777h.045c.429,0,.777-.348.777-.777v-6.445Zm4.4,6.4c0-.429-.348-.777-.777-.777h-.623v-5.623c0-.429-.348-.777-.777-.777h-.045c-.429,0-.777.348-.777.777v6.445c0,.429.348.777.777.777h1.445c.429,0,.777-.348.777-.777v-.045Z" />
      </svg>
    </>
  )
}

export default Fail
