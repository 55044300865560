import { Skill } from '../types'

const skills: Skill[] = [
  {
    image_link:
      'https://static-00.iconduck.com/assets.00/node-js-icon-227x256-913nazt0.png',
    image_alt: "NodeJs's logo",
    website: 'https://nodejs.org/en',
    language_name: 'NodeJs',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png',
    image_alt: "JavaScript's logo",
    website: 'https://www.javascript.com/',
    language_name: 'JavaScript',
  },
  {
    image_link: 'https://grafikart.fr/uploads/icons/typescript.svg',
    image_alt: "TypeScript's logo",
    website: 'https://www.typescriptlang.org/',
    language_name: 'TypeScript',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png',
    image_alt: "Git's logo",
    website: 'https://git-scm.com/doc',
    language_name: 'Git',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png',
    image_alt: "ReactJs's logo",
    website: 'https://fr.legacy.reactjs.org/',
    language_name: 'ReactJs',
  },
  {
    image_link:
      'https://www.drupal.org/files/project-images/nextjs-icon-dark-background.png',
    image_alt: "Next's logo",
    website: 'https://nextjs.org/',
    language_name: 'NextJs',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/1200px-Vue.js_Logo_2.svg.png',
    image_alt: "Vue's logo",
    website: 'https://vuejs.org/',
    language_name: 'VueJs',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg',
    image_alt: "Tailwind's logo",
    website: 'https://tailwindcss.com/',
    language_name: 'Tailwind',
  },
  {
    image_link:
      'https://www.cloudwards.net/wp-content/uploads/2018/09/Shopify-Liquid-ProgLang.png',
    image_alt: "Liquid's logo",
    website:
      'https://www.shopify.com/fr/partenaires/blog/liquid-langage-shopify',
    language_name: 'Liquid',
  },
  {
    image_link: 'https://avatars.githubusercontent.com/u/13810373?s=280&v=4',
    image_alt: "Adonis's logo",
    website: 'https://adonisjs.com/',
    language_name: 'Adonis',
  },
  {
    image_link:
      'https://www.freepnglogos.com/uploads/logo-mysql-png/logo-mysql-mysql-logo-png-images-are-download-crazypng-21.png',
    image_alt: "MySQL's logo",
    website: 'https://dev.mysql.com/doc/',
    language_name: 'MySQL',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/GraphQL_Logo.svg/2048px-GraphQL_Logo.svg.png',
    image_alt: "GraphQl's logo",
    website: 'https://graphql.org/learn/',
    language_name: 'GraphQl',
  },
  {
    image_link:
      'https://assets.super.so/e7c0f16c-8bd3-4c76-8075-4c86f986e1b2/uploads/favicon/9c68ae10-0a8a-4e3f-9084-3625b19df9cb.png',
    image_alt: "Strapi's logo",
    website: 'https://strapi.io/',
    language_name: 'Strapi',
  },
  {
    image_link:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/1200px-Postgresql_elephant.svg.png',
    image_alt: "Postgresql's logo",
    website: 'https://www.postgresql.org/',
    language_name: 'PostgreSQL',
  },
]

export default skills
