const Gitlab = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
      >
        <g>
          <path d="M23.93,13.344c0,0-1.281-3.944-1.281-3.945l-2.541-7.824c-0.138-0.408-0.504-0.671-0.934-0.671c-0.002,0-0.004,0-0.006,0   c-0.43,0.002-0.794,0.268-0.927,0.676l-2.417,7.442H8.18L5.759,1.581C5.626,1.173,5.262,0.907,4.831,0.905   c-0.002,0-0.004,0-0.006,0c-0.43,0-0.796,0.263-0.936,0.677c0,0-2.535,7.819-2.535,7.819L0.07,13.344   c-0.19,0.586,0.016,1.223,0.514,1.585c0,0,11.095,8.061,11.095,8.061c0.109,0.079,0.249,0.116,0.383,0.1   c0.091-0.011,0.182-0.046,0.258-0.1l11.097-8.061C23.914,14.568,24.12,13.931,23.93,13.344z M21.016,10.113l-7.542,9.663   l3.141-9.663H21.016z M12,20.788l-0.273-0.842l-3.192-9.833h6.936L12,20.788z M7.389,10.113l3.137,9.663l-7.537-9.663H7.389z    M19.176,2.231l2.206,6.793h-4.413L19.176,2.231z M4.825,2.231l2.21,6.793H2.622L4.825,2.231z M1.225,14.049   c-0.115-0.084-0.163-0.232-0.119-0.368l0.968-2.97l1.409,1.806l5.711,7.321L1.225,14.049z M22.776,14.049l-7.968,5.788l2.26-2.896   l4.862-6.23l0.964,2.969C22.938,13.817,22.891,13.965,22.776,14.049z" />
        </g>
      </svg>
    </>
  )
}

export default Gitlab
